var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card-code',{attrs:{"title":((_vm.$t('menu.contratos')) + " / " + (_vm.$t('menu.hotels')))}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'hotels-contrate-list' })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiArrowLeft))]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.back')))])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_c('span',[_vm._v(_vm._s(_vm.btnTitle))]),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiUpdate))])],1)],1)],1),_c('v-form',{staticClass:"multi-col-validation"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('strong',{staticStyle:{"opacity":"0"}},[_vm._v(".")]),_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.itemsProveedor,"search-input":_vm.searchProveedor,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.proveedor'),"outlined":"","dense":"","item-text":"name_comercial","item-value":"id"},on:{"update:searchInput":function($event){_vm.searchProveedor=$event},"update:search-input":function($event){_vm.searchProveedor=$event},"change":_vm.changeProveedor},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.proveedor'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name_comercial)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name_comercial))])],1)]}}]),model:{value:(_vm.model.proveedor_id),callback:function ($$v) {_vm.$set(_vm.model, "proveedor_id", $$v)},expression:"model.proveedor_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('strong',{staticStyle:{"opacity":"0"}},[_vm._v(".")]),_c('v-text-field',{staticClass:"pt-2",attrs:{"label":_vm.$t('lbl.identificador'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.identificador),callback:function ($$v) {_vm.$set(_vm.model, "identificador", $$v)},expression:"model.identificador"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('strong',{staticStyle:{"opacity":"0"}},[_vm._v(".")]),_c('v-select',{staticClass:"pt-2",attrs:{"items":_vm.currencies,"label":_vm.$t('register.moneda'),"outlined":"","dense":"","item-text":"code","item-value":"id"},model:{value:(_vm.model.currency_id),callback:function ($$v) {_vm.$set(_vm.model, "currency_id", $$v)},expression:"model.currency_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.hotels')))])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('AsociarHotels',{attrs:{"id-exclude":[]}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.observations')))]),_c('v-tooltip',{staticClass:"text-right",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addObservationContrateHotel()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])])],1),(_vm.observationsHotel.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"12"}},_vm._l((_vm.observationsHotel),function(observation,indO){return _c('Observation',{key:indO,attrs:{"observation":observation,"index":indO}})}),1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }